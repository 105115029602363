import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'

// Components
import CustomLink from 'components/shared/CustomLink'
import ParseContent from 'components/shared/ParseContent'

// SVG
import Logo from 'img/logo.inline.svg'
import ArrowRight from 'img/arrow-right.svg'

const StyledFooter = styled.div`
  background-color: ${(props) => props.theme.color.face.main};
  color: ${(props) => props.theme.color.text.main};
`

const CustomCol = styled.div`
  border-bottom: 2px solid ${(props) => props.theme.color.face.secondary};
  color: ${(props) => props.theme.color.text.main};

  @media (min-width: 1200px) {
    border: unset;
    border-right: 2px solid ${(props) => props.theme.color.face.secondary};
    &:last-child {
      border: unset;
    }
  }
`

const StyledCustomLink = styled(CustomLink)`
  max-width: 220px;
  background-color: ${(props) => props.theme.color.face.light};
  color: ${(props) => props.theme.color.text.main};
  border: 1px solid ${(props) => props.theme.color.face.secondary};
  padding: 20px 15px;
  width: 100%;
  height: 100%;
  display: block;
  cursor: pointer;
  text-align: center;
`

const MenuContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  color: ${(props) => props.theme.color.text.main};

  & > ul {
    list-style-type: none;
    text-transform: lowercase;
    font-family: ${(props) => props.theme.font.family.main};
    font-weight: ${(props) => props.theme.font.weight.xs};
    color: ${(props) => props.theme.color.text.main};
    & li > a:hover {
      text-decoration: underline;
    }
    & > li:last-child > a {
      background-color: ${(props) => props.theme.color.face.light};
      text-decoration: none;
      padding: 0.75rem 1rem !important;
      transition: background-color 0.2s ease-in-out;

      &:hover {
        background-color: ${(props) => props.theme.color.face.secondary};
      }

      &:after {
        content: url(${ArrowRight});
        padding-left: ${(props) => props.theme.font.spacing.l};
      }
    }
  }
`

const ImageContainer = styled.div`
  max-height: 40px;
  min-height: 40px;
`

const Content = styled(ParseContent)`
  & h1,
  & h2,
  & h3,
  & h4,
  & h5 {
    font-size: ${(props) => props.theme.font.size['14']};
    color: ${(props) => props.theme.color.text.dark};
    margin-bottom: 1.4rem;
  }

  & p {
    line-height: 14px;
  }

  & a:hover {
    text-decoration: underline;
  }
`

const Brand = styled(CustomLink)`
  & > svg {
    width: 165px;
  }
  @media (max-width: 775px) {
    width: 100%;
  }
`

const StyledImage = styled.img`
  object-fit: contain !important;
`

const Footer = () => {
  // eslint-disable-next-line
  const { fields } = useStaticQuery<GatsbyTypes.footerQueryQuery>(graphql`
    query footerQuery {
      fields: wpComponent(databaseId: { eq: 98 }) {
        footer {
          infoFooter {
            info {
              image {
                localFile {
                  publicURL
                }
              }
              description
            }
          }
          menuFooter {
            link {
              title
              url
            }
          }
        }
      }
    }
  `)

  return (
    <StyledFooter>
      <div className="container-lg pt-5 pb-2">
        <div className="row justify-content-center">
          {fields?.footer?.infoFooter?.map((edge: any, index: number) => (
            <CustomCol
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              className="col-xl-4 col-md-6 col-12 d-flex flex-column justify-content-evenly py-5"
            >
              <ImageContainer className="d-flex justify-content-center mb-1">
                <StyledImage
                  src={edge.info.image.localFile.publicURL}
                  alt="Lofthus"
                />
              </ImageContainer>
              <Content content={edge.info.description} />
            </CustomCol>
          ))}
        </div>
        <div className="d-flex d-xl-none justify-content-center justify-content-sm-around flex-sm-row flex-column my-5">
          <StyledCustomLink
            to="https://shop.lofthus.nl"
            className="mb-2 mb-sm-0 mx-auto"
            target="_blank"
            rel="noopener noreferrer"
          >
            shop online
          </StyledCustomLink>
          <StyledCustomLink
            to="/veelgestelde-vragen"
            target="_blank"
            rel="noopener noreferrer"
            className="mx-auto"
          >
            veelgestelde vragen
          </StyledCustomLink>
        </div>

        <MenuContainer className="flex-wrap flex-xl-nowrap text-center justify-content-center justify-content-xl-between mt-5">
          <Brand to="/">
            <Logo />
          </Brand>

          <ul className="d-xl-flex d-none justify-content-end">
            {fields?.footer?.menuFooter?.map(
              ({ link: { url, title } }: any, index: number) => (
                // eslint-disable-next-line react/no-array-index-key
                <li key={index}>
                  <CustomLink className="mr-4 py-3" to={url}>
                    {title}
                  </CustomLink>
                </li>
              )
            )}
          </ul>
        </MenuContainer>
      </div>
    </StyledFooter>
  )
}

export default Footer
