import React from 'react'
import styled from 'styled-components'
import ButtonShell from 'components/elements/ButtonShell'
import { motion } from 'framer-motion'

const StyledButton = styled(motion.span)`
  display: inline-block;
  min-width: 220px;
  text-align: center;

  & > a,
  & > button {
    background-color: ${(props) => props.theme.color.face.light};
    color: ${(props) => props.theme.color.text.main};
    border: 1px solid ${(props) => props.theme.color.face.secondary};
    padding: 20px 15px;
    width: 100%;
    height: 100%;
    display: block;
    cursor: pointer;
  }
`

interface ButtonDefaultProps {
  isAnchor?: boolean
  isCustom?: boolean
  to: string
  children: any
  className?: string
}

const ButtonWhite: React.FC<ButtonDefaultProps> = ({
  isAnchor = false,
  isCustom = false,
  to,
  children,
  className = '',
}) => (
  <StyledButton
    className={className}
    whileHover={{ scale: 1.1 }}
    whileTap={{ scale: 0.9 }}
  >
    {isCustom ? (
      children
    ) : (
      <ButtonShell to={to} isAnchor={isAnchor}>
        {children}
      </ButtonShell>
    )}
  </StyledButton>
)

export default ButtonWhite
