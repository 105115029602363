import React from 'react'
import styled from 'styled-components'

const Title = styled.h2`
  font-size: ${(props) => props.theme.font.size['21']};
  font-weight: ${(props) => props.theme.font.weight.s};
`

const AfterTitle = styled.div`
  width: 95%;
  border-top: 2px solid ${(props) => props.theme.color.face.secondary};
  position: relative;
  top: 11px;
`

interface TitleDefaultProps {
  className?: string
  children: any
}

const TitleDefault: React.FC<TitleDefaultProps> = ({
  className = '',
  children,
}) => (
  <div className="d-flex my-5 w-100">
    <Title className={className}>{children}</Title>
    <div className="w-100 d-flex justify-content-end">
      <AfterTitle />
    </div>
  </div>
)

export default TitleDefault
