const mainTheme = {
  color: {
    face: {
      main: '#E5DFD5',
      mainLight: '#F5F3EF',
      mainDark: '#D1CBC0',
      contrast: '#EB6209',
      secondary: '#C0B6A5',
      light: '#FFFFFF',
      dark: '#000000',
    },

    text: {
      main: '#505050',
      contrast: '#EB6209',
      secondary: '#e3e3e3',
      light: '#FFFFFF',
      dark: '#000000',
    },
  },

  font: {
    family: {
      main: 'Helvetica Neue, Helvetica, Arial, sans-serif',
      secondary: 'Verdana, Arial',
    },

    size: {
      8: '8px',
      10: '10px',
      12: '12px',
      14: '14px',
      16: '16px',
      18: '18px',
      20: '20px',
      21: '21px',
      24: '24px',
      28: '28px',
      32: '32px',
      45: '45px',
      70: '70px',
    },

    weight: {
      xs: 200,
      s: 300,
      m: 400,
      l: 600,
      xl: 800,
    },

    spacing: {
      xs: '2px',
      s: '3px',
      m: '4px',
      l: '5px',
      xl: '6px',
    },
  },
}

export default mainTheme
